import type { IconName } from '@st/ui/components/StIcon/types'
import { groupBy } from '@st/utils'

interface Country {
  code: string
  name: string
  icon: IconName
}

interface UseCountriesReturn {
  countries: Country[]
  getCountryByCode: (code: string) => Country | undefined
}

export function useCountries(): UseCountriesReturn {
  const { t } = useI18n()

  const countries: Country[] = [
    { code: 'aus', name: t('countries.aus'), icon: 'f-aus' },
    { code: 'aut', name: t('countries.aut'), icon: 'f-aut' },
    { code: 'aze', name: t('countries.aze'), icon: 'f-aze' },
    { code: 'alb', name: t('countries.alb'), icon: 'f-alb' },
    { code: 'dza', name: t('countries.dza'), icon: 'f-dza' },
    { code: 'vir', name: t('countries.vir'), icon: 'f-vir' },
    { code: 'asm', name: t('countries.asm'), icon: 'f-asm' },
    { code: 'aia', name: t('countries.aia'), icon: 'f-aia' },
    { code: 'ago', name: t('countries.ago'), icon: 'f-ago' },
    { code: 'and', name: t('countries.and'), icon: 'f-and' },
    { code: 'atg', name: t('countries.atg'), icon: 'f-atg' },
    { code: 'arg', name: t('countries.arg'), icon: 'f-arg' },
    { code: 'arm', name: t('countries.arm'), icon: 'f-arm' },
    { code: 'abw', name: t('countries.abw'), icon: 'f-abw' },
    { code: 'afg', name: t('countries.afg'), icon: 'f-afg' },
    { code: 'bhs', name: t('countries.bhs'), icon: 'f-bhs' },
    { code: 'bgd', name: t('countries.bgd'), icon: 'f-bgd' },
    { code: 'brb', name: t('countries.brb'), icon: 'f-brb' },
    { code: 'bhr', name: t('countries.bhr'), icon: 'f-bhr' },
    { code: 'blz', name: t('countries.blz'), icon: 'f-blz' },
    { code: 'blr', name: t('countries.blr'), icon: 'f-blr' },
    { code: 'bel', name: t('countries.bel'), icon: 'f-bel' },
    { code: 'ben', name: t('countries.ben'), icon: 'f-ben' },
    { code: 'bmu', name: t('countries.bmu'), icon: 'f-bmu' },
    { code: 'bgr', name: t('countries.bgr'), icon: 'f-bgr' },
    { code: 'bol', name: t('countries.bol'), icon: 'f-bol' },
    { code: 'bes', name: t('countries.bes'), icon: 'f-bes' },
    { code: 'bih', name: t('countries.bih'), icon: 'f-bih' },
    { code: 'bwa', name: t('countries.bwa'), icon: 'f-bwa' },
    { code: 'bra', name: t('countries.bra'), icon: 'f-bra' },
    { code: 'iot', name: t('countries.iot'), icon: 'f-iot' },
    { code: 'vgb', name: t('countries.vgb'), icon: 'f-vgb' },
    { code: 'brn', name: t('countries.brn'), icon: 'f-brn' },
    { code: 'bfa', name: t('countries.bfa'), icon: 'f-bfa' },
    { code: 'bdi', name: t('countries.bdi'), icon: 'f-bdi' },
    { code: 'btn', name: t('countries.btn'), icon: 'f-btn' },
    { code: 'vut', name: t('countries.vut'), icon: 'f-vut' },
    { code: 'vat', name: t('countries.vat'), icon: 'f-vat' },
    { code: 'gbr', name: t('countries.gbr'), icon: 'f-gbr' },
    { code: 'hun', name: t('countries.hun'), icon: 'f-hun' },
    { code: 'ven', name: t('countries.ven'), icon: 'f-ven' },
    { code: 'tls', name: t('countries.tls'), icon: 'f-tls' },
    { code: 'vnm', name: t('countries.vnm'), icon: 'f-vnm' },
    { code: 'gab', name: t('countries.gab'), icon: 'f-gab' },
    { code: 'hti', name: t('countries.hti'), icon: 'f-hti' },
    { code: 'guy', name: t('countries.guy'), icon: 'f-guy' },
    { code: 'gmb', name: t('countries.gmb'), icon: 'f-gmb' },
    { code: 'gha', name: t('countries.gha'), icon: 'f-gha' },
    { code: 'glp', name: t('countries.glp'), icon: 'f-fra' },
    { code: 'gtm', name: t('countries.gtm'), icon: 'f-gtm' },
    { code: 'gin', name: t('countries.gin'), icon: 'f-gin' },
    { code: 'gnb', name: t('countries.gnb'), icon: 'f-gnb' },
    { code: 'deu', name: t('countries.deu'), icon: 'f-deu' },
    { code: 'ggy', name: t('countries.ggy'), icon: 'f-ggy' },
    { code: 'gib', name: t('countries.gib'), icon: 'f-gib' },
    { code: 'hnd', name: t('countries.hnd'), icon: 'f-hnd' },
    { code: 'hkg', name: t('countries.hkg'), icon: 'f-hkg' },
    { code: 'grd', name: t('countries.grd'), icon: 'f-grd' },
    { code: 'grl', name: t('countries.grl'), icon: 'f-grl' },
    { code: 'grc', name: t('countries.grc'), icon: 'f-grc' },
    { code: 'geo', name: t('countries.geo'), icon: 'f-geo' },
    { code: 'gum', name: t('countries.gum'), icon: 'f-gum' },
    { code: 'dnk', name: t('countries.dnk'), icon: 'f-dnk' },
    { code: 'cod', name: t('countries.cod'), icon: 'f-cod' },
    { code: 'jey', name: t('countries.jey'), icon: 'f-jey' },
    { code: 'dji', name: t('countries.dji'), icon: 'f-dji' },
    { code: 'dma', name: t('countries.dma'), icon: 'f-dma' },
    { code: 'dom', name: t('countries.dom'), icon: 'f-dom' },
    { code: 'egy', name: t('countries.egy'), icon: 'f-egy' },
    { code: 'zmb', name: t('countries.zmb'), icon: 'f-zmb' },
    { code: 'esh', name: t('countries.esh'), icon: 'f-esh' },
    { code: 'zwe', name: t('countries.zwe'), icon: 'f-zwe' },
    { code: 'isr', name: t('countries.isr'), icon: 'f-isr' },
    { code: 'ind', name: t('countries.ind'), icon: 'f-ind' },
    { code: 'idn', name: t('countries.idn'), icon: 'f-idn' },
    { code: 'jor', name: t('countries.jor'), icon: 'f-jor' },
    { code: 'irq', name: t('countries.irq'), icon: 'f-irq' },
    { code: 'irn', name: t('countries.irn'), icon: 'f-irn' },
    { code: 'irl', name: t('countries.irl'), icon: 'f-irl' },
    { code: 'isl', name: t('countries.isl'), icon: 'f-isl' },
    { code: 'esp', name: t('countries.esp'), icon: 'f-esp' },
    { code: 'ita', name: t('countries.ita'), icon: 'f-ita' },
    { code: 'yem', name: t('countries.yem'), icon: 'f-yem' },
    { code: 'cpv', name: t('countries.cpv'), icon: 'f-cpv' },
    { code: 'kaz', name: t('countries.kaz'), icon: 'f-kaz' },
    { code: 'cym', name: t('countries.cym'), icon: 'f-cym' },
    { code: 'khm', name: t('countries.khm'), icon: 'f-khm' },
    { code: 'cmr', name: t('countries.cmr'), icon: 'f-cmr' },
    { code: 'can', name: t('countries.can'), icon: 'f-can' },
    { code: 'qat', name: t('countries.qat'), icon: 'f-qat' },
    { code: 'ken', name: t('countries.ken'), icon: 'f-ken' },
    { code: 'cyp', name: t('countries.cyp'), icon: 'f-cyp' },
    { code: 'kgz', name: t('countries.kgz'), icon: 'f-kgz' },
    { code: 'kir', name: t('countries.kir'), icon: 'f-kir' },
    { code: 'chn', name: t('countries.chn'), icon: 'f-chn' },
    { code: 'prk', name: t('countries.prk'), icon: 'f-prk' },
    { code: 'cck', name: t('countries.cck'), icon: 'f-cck' },
    { code: 'col', name: t('countries.col'), icon: 'f-col' },
    { code: 'com', name: t('countries.com'), icon: 'f-com' },
    { code: 'kor', name: t('countries.kor'), icon: 'f-kor' },
    { code: 'kos', name: t('countries.kos'), icon: 'planet' },
    { code: 'cri', name: t('countries.cri'), icon: 'f-cri' },
    { code: 'civ', name: t('countries.civ'), icon: 'f-civ' },
    { code: 'cub', name: t('countries.cub'), icon: 'f-cub' },
    { code: 'kwt', name: t('countries.kwt'), icon: 'f-kwt' },
    { code: 'cuw', name: t('countries.cuw'), icon: 'f-cuw' },
    { code: 'lao', name: t('countries.lao'), icon: 'f-lao' },
    { code: 'lva', name: t('countries.lva'), icon: 'f-lva' },
    { code: 'lso', name: t('countries.lso'), icon: 'f-lso' },
    { code: 'lbr', name: t('countries.lbr'), icon: 'f-lbr' },
    { code: 'lbn', name: t('countries.lbn'), icon: 'f-lbn' },
    { code: 'lby', name: t('countries.lby'), icon: 'f-lby' },
    { code: 'ltu', name: t('countries.ltu'), icon: 'f-ltu' },
    { code: 'lie', name: t('countries.lie'), icon: 'f-lie' },
    { code: 'lux', name: t('countries.lux'), icon: 'f-lux' },
    { code: 'mus', name: t('countries.mus'), icon: 'f-mus' },
    { code: 'mrt', name: t('countries.mrt'), icon: 'f-mrt' },
    { code: 'mdg', name: t('countries.mdg'), icon: 'f-mdg' },
    { code: 'myt', name: t('countries.myt'), icon: 'planet' },
    { code: 'mac', name: t('countries.mac'), icon: 'f-mac' },
    { code: 'mkd', name: t('countries.mkd'), icon: 'f-mkd' },
    { code: 'mwi', name: t('countries.mwi'), icon: 'f-mwi' },
    { code: 'mys', name: t('countries.mys'), icon: 'f-mys' },
    { code: 'mli', name: t('countries.mli'), icon: 'f-mli' },
    { code: 'mdv', name: t('countries.mdv'), icon: 'f-mdv' },
    { code: 'mlt', name: t('countries.mlt'), icon: 'f-mlt' },
    { code: 'mar', name: t('countries.mar'), icon: 'f-mar' },
    { code: 'mtq', name: t('countries.mtq'), icon: 'f-mtq' },
    { code: 'mhl', name: t('countries.mhl'), icon: 'f-mhl' },
    { code: 'mex', name: t('countries.mex'), icon: 'f-mex' },
    { code: 'moz', name: t('countries.moz'), icon: 'f-moz' },
    { code: 'mda', name: t('countries.mda'), icon: 'f-mda' },
    { code: 'mco', name: t('countries.mco'), icon: 'f-mco' },
    { code: 'mng', name: t('countries.mng'), icon: 'f-mng' },
    { code: 'msr', name: t('countries.msr'), icon: 'f-msr' },
    { code: 'mmr', name: t('countries.mmr'), icon: 'f-mmr' },
    { code: 'nam', name: t('countries.nam'), icon: 'f-nam' },
    { code: 'nru', name: t('countries.nru'), icon: 'f-nru' },
    { code: 'npl', name: t('countries.npl'), icon: 'f-npl' },
    { code: 'ner', name: t('countries.ner'), icon: 'f-ner' },
    { code: 'nga', name: t('countries.nga'), icon: 'f-nga' },
    { code: 'ant', name: t('countries.ant'), icon: 'planet' },
    { code: 'nld', name: t('countries.nld'), icon: 'f-nld' },
    { code: 'nic', name: t('countries.nic'), icon: 'f-nic' },
    { code: 'niu', name: t('countries.niu'), icon: 'f-niu' },
    { code: 'nzl', name: t('countries.nzl'), icon: 'f-nzl' },
    { code: 'ncl', name: t('countries.ncl'), icon: 'planet' },
    { code: 'nor', name: t('countries.nor'), icon: 'f-nor' },
    { code: 'are', name: t('countries.are'), icon: 'f-are' },
    { code: 'omn', name: t('countries.omn'), icon: 'f-omn' },
    { code: 'nfk', name: t('countries.nfk'), icon: 'f-nfk' },
    { code: 'cxr', name: t('countries.cxr'), icon: 'f-cxr' },
    { code: 'shn', name: t('countries.shn'), icon: 'planet' },
    { code: 'hmd', name: t('countries.hmd'), icon: 'planet' },
    { code: 'cok', name: t('countries.cok'), icon: 'f-cok' },
    { code: 'pcn', name: t('countries.pcn'), icon: 'f-pcn' },
    { code: 'tca', name: t('countries.tca'), icon: 'f-tca' },
    { code: 'wlf', name: t('countries.wlf'), icon: 'planet' },
    { code: 'pak', name: t('countries.pak'), icon: 'f-pak' },
    { code: 'plw', name: t('countries.plw'), icon: 'f-plw' },
    { code: 'pse', name: t('countries.pse'), icon: 'f-pse' },
    { code: 'pan', name: t('countries.pan'), icon: 'f-pan' },
    { code: 'png', name: t('countries.png'), icon: 'f-png' },
    { code: 'pry', name: t('countries.pry'), icon: 'f-pry' },
    { code: 'per', name: t('countries.per'), icon: 'f-per' },
    { code: 'pol', name: t('countries.pol'), icon: 'f-pol' },
    { code: 'prt', name: t('countries.prt'), icon: 'f-prt' },
    { code: 'pri', name: t('countries.pri'), icon: 'f-pri' },
    { code: 'cog', name: t('countries.cog'), icon: 'f-cog' },
    { code: 'reu', name: t('countries.reu'), icon: 'planet' },
    { code: 'rus', name: t('countries.rus'), icon: 'f-rus' },
    { code: 'rwa', name: t('countries.rwa'), icon: 'f-rwa' },
    { code: 'rou', name: t('countries.rou'), icon: 'f-rou' },
    { code: 'slv', name: t('countries.slv'), icon: 'f-slv' },
    { code: 'wsm', name: t('countries.wsm'), icon: 'f-wsm' },
    { code: 'smr', name: t('countries.smr'), icon: 'f-smr' },
    { code: 'stp', name: t('countries.stp'), icon: 'f-stp' },
    { code: 'sau', name: t('countries.sau'), icon: 'f-sau' },
    { code: 'swz', name: t('countries.swz'), icon: 'f-swz' },
    { code: 'mnp', name: t('countries.mnp'), icon: 'f-mnp' },
    { code: 'syc', name: t('countries.syc'), icon: 'f-syc' },
    { code: 'spm', name: t('countries.spm'), icon: 'planet' },
    { code: 'sen', name: t('countries.sen'), icon: 'f-sen' },
    { code: 'vct', name: t('countries.vct'), icon: 'f-vct' },
    { code: 'kna', name: t('countries.kna'), icon: 'f-kna' },
    { code: 'lca', name: t('countries.lca'), icon: 'f-lca' },
    { code: 'srb', name: t('countries.srb'), icon: 'f-srb' },
    { code: 'sgp', name: t('countries.sgp'), icon: 'f-sgp' },
    { code: 'syr', name: t('countries.syr'), icon: 'f-syr' },
    { code: 'svk', name: t('countries.svk'), icon: 'f-svk' },
    { code: 'svn', name: t('countries.svn'), icon: 'f-svn' },
    { code: 'slb', name: t('countries.slb'), icon: 'f-slb' },
    { code: 'som', name: t('countries.som'), icon: 'f-som' },
    { code: 'sdn', name: t('countries.sdn'), icon: 'f-sdn' },
    { code: 'sur', name: t('countries.sur'), icon: 'f-sur' },
    { code: 'usa', name: t('countries.usa'), icon: 'f-usa' },
    { code: 'sle', name: t('countries.sle'), icon: 'f-sle' },
    { code: 'tjk', name: t('countries.tjk'), icon: 'f-tjk' },
    { code: 'tha', name: t('countries.tha'), icon: 'f-tha' },
    { code: 'twn', name: t('countries.twn'), icon: 'f-twn' },
    { code: 'tza', name: t('countries.tza'), icon: 'f-tza' },
    { code: 'tgo', name: t('countries.tgo'), icon: 'f-tgo' },
    { code: 'tkl', name: t('countries.tkl'), icon: 'f-tkl' },
    { code: 'ton', name: t('countries.ton'), icon: 'f-ton' },
    { code: 'tto', name: t('countries.tto'), icon: 'f-tto' },
    { code: 'tuv', name: t('countries.tuv'), icon: 'f-tuv' },
    { code: 'tun', name: t('countries.tun'), icon: 'f-tun' },
    { code: 'tkm', name: t('countries.tkm'), icon: 'f-tkm' },
    { code: 'tur', name: t('countries.tur'), icon: 'f-tur' },
    { code: 'uga', name: t('countries.uga'), icon: 'f-uga' },
    { code: 'uzb', name: t('countries.uzb'), icon: 'f-uzb' },
    { code: 'ukr', name: t('countries.ukr'), icon: 'f-ukr' },
    { code: 'ury', name: t('countries.ury'), icon: 'f-ury' },
    { code: 'fro', name: t('countries.fro'), icon: 'f-fro' },
    { code: 'fsm', name: t('countries.fsm'), icon: 'f-fsm' },
    { code: 'fji', name: t('countries.fji'), icon: 'f-fji' },
    { code: 'phl', name: t('countries.phl'), icon: 'f-phl' },
    { code: 'fin', name: t('countries.fin'), icon: 'f-fin' },
    { code: 'flk', name: t('countries.flk'), icon: 'f-flk' },
    { code: 'fra', name: t('countries.fra'), icon: 'f-fra' },
    { code: 'guf', name: t('countries.guf'), icon: 'f-guf' },
    { code: 'pyf', name: t('countries.pyf'), icon: 'f-pyf' },
    { code: 'atf', name: t('countries.atf'), icon: 'planet' },
    { code: 'hrv', name: t('countries.hrv'), icon: 'f-hrv' },
    { code: 'caf', name: t('countries.caf'), icon: 'f-caf' },
    { code: 'tcd', name: t('countries.tcd'), icon: 'f-tcd' },
    { code: 'mne', name: t('countries.mne'), icon: 'f-mne' },
    { code: 'cze', name: t('countries.cze'), icon: 'f-cze' },
    { code: 'chl', name: t('countries.chl'), icon: 'f-chl' },
    { code: 'che', name: t('countries.che'), icon: 'f-che' },
    { code: 'swe', name: t('countries.swe'), icon: 'f-swe' },
    { code: 'sjm', name: t('countries.sjm'), icon: 'planet' },
    { code: 'lka', name: t('countries.lka'), icon: 'f-lka' },
    { code: 'ecu', name: t('countries.ecu'), icon: 'f-ecu' },
    { code: 'gnq', name: t('countries.gnq'), icon: 'f-gnq' },
    { code: 'eri', name: t('countries.eri'), icon: 'f-eri' },
    { code: 'est', name: t('countries.est'), icon: 'f-est' },
    { code: 'eth', name: t('countries.eth'), icon: 'f-eth' },
    { code: 'zaf', name: t('countries.zaf'), icon: 'f-zaf' },
    { code: 'sgs', name: t('countries.sgs'), icon: 'planet' },
    { code: 'jam', name: t('countries.jam'), icon: 'f-jam' },
    { code: 'jpn', name: t('countries.jpn'), icon: 'f-jpn' },
    { code: 'eng', name: t('countries.eng'), icon: 'f-eng' },
    { code: 'wal', name: t('countries.wal'), icon: 'f-wal' },
    { code: 'sco', name: t('countries.sco'), icon: 'f-sco' },
    { code: 'ssd', name: t('countries.ssd'), icon: 'f-ssd' },
  ]

  const countriesByCode = groupBy(countries, (country) => country.code)

  function getCountryByCode(code: string) {
    return countriesByCode.get(code.trim().toLowerCase().slice(0, 3))?.[0]
  }

  return {
    countries,
    getCountryByCode,
  }
}
